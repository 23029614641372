import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'

const getCategoryI18Key = (category: string) => `crowdin:material-components.${category}`
const Categories = ({
  attributes: { materialComposableFeatures },
  isHorizontal,
}: {
  attributes: ProductType.Data['attributes']
  isHorizontal?: boolean
}) => {
  const { t, i18n } = useTranslation()
  const categories = useMemo(
    () =>
      materialComposableFeatures
        ? materialComposableFeatures.filter(category => i18n.exists(getCategoryI18Key(category)))
        : [],
    [materialComposableFeatures, i18n],
  )
  if (!categories.length) {
    return isHorizontal ? null : (
      <div data-testid='product-card-categories-empty' className='min-h-[33px]' />
    )
  }
  return (
    <div
      data-testid='product-card-categories'
      className={twMerge(
        'flex items-center gap-2 text-grayscale-800',
        isHorizontal ? 'min-h-[auto]' : ' min-h-[33px]',
      )}
    >
      <Icon type='grid_view' />
      <ul className='m-0 flex flex-grow list-none items-center p-0 clamp-line-1'>
        {categories.map(category => (
          <li key={category} className='inline-block w-fit whitespace-nowrap'>
            <Text variant='label_s_400' className='align-text-top'>
              {t(getCategoryI18Key(category))}
              {categories.at(-1) !== category && '・'}
            </Text>
          </li>
        ))}
      </ul>
    </div>
  )
}
export default Categories
