import { differenceInDays, parseISO, isSameDay, isAfter } from 'date-fns'

const DEFAULT_CLASS_NAME = 'bg-gradient-to-r from-purple-500 to-primary-500' as const
const getCountdownLabel = (countdown: ProductType.Data['attributes']['countdown']) => {
  if (!countdown) return

  const today = new Date()
  const endsAt = parseISO(countdown.endsAt)

  switch (countdown.style) {
    case 'limited_qty_offer': {
      return {
        i18Key: 'crowdin:product-detail-page.info.limited-to-x',
        params: { amount: countdown.totalCount - countdown.currentCount },
        className: DEFAULT_CLASS_NAME,
      }
    }
    case 'crowdfunding':
    case 'limited_time_offer': {
      return {
        i18Key: 'crowdin:product-card.limited-time-offer',
        params: { days: differenceInDays(endsAt, today) },
        className: DEFAULT_CLASS_NAME,
      }
    }
    case 'before_on_sale': {
      return {
        i18Key: isSameDay(endsAt, today)
          ? 'crowdin:product-card.on-sale.first-day'
          : 'crowdin:product-card.before-on-sale',
        params: { days: differenceInDays(endsAt, today) },
        className: DEFAULT_CLASS_NAME,
      }
    }
    default: {
      return
    }
  }
}

export default getCountdownLabel
