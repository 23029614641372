import { twMerge } from 'tailwind-merge'
import { useRouter } from 'next/router'
import { formatMoney } from '@libs-components/utils/formatter'
import { Text } from '@libs-components/components/text'
import DiscountText from '@/components/elements/discount-text'

const Prices = ({
  attributes,
  isHorizontal,
}: {
  attributes: ProductType.Data['attributes']
  isHorizontal?: boolean
}) => {
  const isHomePage = useRouter().pathname === '/'
  const salePrice = Number(attributes.salePrice)
  const listPrice = Number(attributes.listPrice)
  if (isHomePage) return null
  return (
    <div
      data-testid='product-card-prices'
      className={twMerge(
        'flex gap-2',
        isHorizontal ? 'mobileUp:flex-col-reverse mobileUp:gap-0' : 'items-center',
      )}
    >
      <Text
        variant='body_l_500'
        color='peach-500'
        className={twMerge(isHorizontal && '!text-[1rem] mobileUp:!text-[1.25rem]')}
      >
        {formatMoney(salePrice)}
      </Text>
      <div className='flex flex-wrap items-center gap-1'>
        <Text variant='body_s_400' color='grayscale-500' className='line-through'>
          {formatMoney(listPrice)}
        </Text>
        <DiscountText
          variant='body_s_400'
          parentheses
          salePrice={salePrice}
          listPrice={listPrice}
          color='grayscale-500'
        />
      </div>
    </div>
  )
}
export default Prices
