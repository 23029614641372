import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'next-i18next'
import { Text } from '@libs-components/components/text'
import getCountdownLabel from './get-countdown-label'

const HIGHLIGHT_CLASSNAME = 'bg-gradient-to-r from-purple-500 to-primary-500' as const

const HIGHLIGHTED_TAGS_OF_LEARNING_PROGRAM = '陪讀計畫' as const

const Labels = ({
  attributes: { productLine, countdown, isCashbackMission, highlightedTags },
}: {
  attributes: ProductType.Data['attributes']
}) => {
  const { t, i18n } = useTranslation()
  const labels = [
    productLine && { i18Key: `crowdin:product-line.${productLine}` },
    getCountdownLabel(countdown),
    highlightedTags?.includes(HIGHLIGHTED_TAGS_OF_LEARNING_PROGRAM) && {
      i18Key: 'crowdin:product-card.learning-program',
      className: HIGHLIGHT_CLASSNAME,
    },
    isCashbackMission &&
      !countdown?.style && {
        i18Key: 'crowdin:product-card.money-back-mission',
        className: HIGHLIGHT_CLASSNAME,
      },
  ].filter(label => label && i18n.exists(label.i18Key)) as {
    i18Key: string
    params?: Record<string, unknown>
    className?: string
  }[]

  return (
    <ul
      data-testid='product-card-labels'
      className='m-0 flex min-h-[24px] list-none flex-wrap items-center gap-2 p-0 text-white'
    >
      {labels.map(({ i18Key, params = {}, className }) => (
        <li
          key={i18Key}
          className={twMerge(
            'flex w-fit items-center justify-center rounded-2xl bg-grayscale-800 px-3 py-[1.5px]',
            className,
          )}
        >
          <Text variant='label_s_400' color='grayscale-000'>
            {t(i18Key, params)}
          </Text>
        </li>
      ))}
    </ul>
  )
}

export default Labels
